import * as React from "react";
import { useConfig } from "@fitplan/context/lib-es/config";

import { tatariIdentify, tatariTrack } from "./tatari";

interface UseTatari {
    tatariIdentify: (userId: number) => void;
    tatariTrack: (event: string, data?: number | string | { [key: string]: any }) => void;
}

export const useTatari = (): UseTatari => {
    const { isProduction } = useConfig();

    return React.useMemo(() => {
        if (isProduction) {
            return { tatariIdentify, tatariTrack };
        } else {
            const noop = () => {};
            return {
                tatariIdentify: noop,
                tatariTrack: noop,
            }
        }
    }, []);
}
