import * as React from "react";

export const GoogleIcon: React.SFC<{}> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        {...props}
    >
        <path
            fill="#FFF"
            fillRule="nonzero"
            d="M24.24 9.818H12.785v4.909h6.492c-1.037 3.273-3.6 4.364-6.544 4.364a7.091 7.091 0 1 1 4.553-12.52l3.567-3.4A12 12 0 1 0 12.733 24c6.617 0 12.6-4.364 11.507-14.182z"
        />
    </svg>
);

export const FacebookIcon: React.SFC<{}> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        viewBox="0 0 25 24"
        {...props}
    >
        <path
            fill="#FFF"
            fillRule="nonzero"
            d="M23.13 0H1.497A.993.993 0 0 0 .5.997v21.67c0 .517.443.96.997.96h11.555v-8.86h-2.954v-3.692h2.954V8.122c0-3.064 1.92-4.541 4.651-4.541 1.292 0 2.437.11 2.769.148v3.174h-1.883c-1.476 0-1.809.702-1.809 1.736v2.436h3.692l-.738 3.692H16.78l.074 8.86h6.35a.993.993 0 0 0 .997-.997V.997C24.127.443 23.684 0 23.13 0z"
        />
    </svg>
);
